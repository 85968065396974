import React from 'react';
import PropTypes from 'prop-types';
import { Blank } from 'grommet-icons';

const IconCoverage = (props) => (
  <Blank
    viewBox="0 0 36 36"
    aria-hidden="true"
    focusable="false"
    role="presentation"
    {...props}
  >
    <g fillRule="nonzero" strokeWidth="2" fill="none" stroke={props.color}>
      <path
        d="M1.904 1.884h32.552v32.552H1.904z"
        fill="none"
        stroke={props.color}
      />
      <path
        fill="none"
        stroke={props.color}
        strokeLinecap="square"
        d="M2.235 24.809l9.054-2.064-1.697-6.333 9.413-2.522 2.522 9.414 7.702-2.064-4.127-15.404 9.025-2.294"
      />
    </g>
  </Blank>
);

IconCoverage.propTypes = {
  color: PropTypes.string,
};

export default IconCoverage;
